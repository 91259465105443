import renderTemplate from './renderer';
import {ENRICHMENTS_INDICATOR, XandrNativeAdObjectConfig} from './renderer/model/NativeAdObject';
import {getTemplateNameFromProduct} from './util/product-util';
import {isObject} from './util/object-util';
import logger from './util/logger';

declare global {
	interface Window {
		advert: {
			nativeTemplates: {
				render: typeof render
				templates?: Array<NativeTemplate>
				products?: Record<string, NativeTemplateProduct>
				renderConfig?: RenderConfig
				feedContext?: Record<string, string>
			},
			getActiveExperiments?: () => Array<{ name: string, group: string }>
		}
	}
}

window.advert = window.advert || {};
window.advert.nativeTemplates = window.advert.nativeTemplates || {};
window.advert.nativeTemplates.render = render;

function render(adObjectCfg: XandrNativeAdObjectConfig, nativeTemplate: NativeTemplate | NativeTemplateProduct | string, renderCfg?: RenderConfig, containerEl?: HTMLElement) {
	try {
		const renderConfig = renderCfg ?? window.advert.nativeTemplates.renderConfig ?? {},
			template: NativeTemplate | null = getTemplate(nativeTemplate, renderConfig, containerEl);

		if (template === null) {
			throw new Error('Could not find native template');
		}

		const component = renderTemplate(adObjectCfg, template, renderConfig, typeof nativeTemplate === 'string' ? _getProductName(nativeTemplate) : undefined);

		if (containerEl) {
			containerEl.appendChild(component);
		}

		return component;
	} catch (e) {
		logger.error('Something went wrong rendering a native template | Template:', nativeTemplate, '| Error:', e);

		throw e;
	}
}

export function getTemplate(nativeProductOrTemplate: NativeTemplate | NativeTemplateProduct | string, renderConfig: RenderConfig, containerEl?: HTMLElement): NativeTemplate | null {
	const spec = _getSpec(nativeProductOrTemplate);

	if (_isProduct(spec)) {
		const templateName = getTemplateNameFromProduct(spec, renderConfig, containerEl);

		return _getTemplateFromName(templateName);
	}

	return spec;
}


function _getSpec(nativeProductOrTemplate: NativeTemplate | NativeTemplateProduct | string): NativeTemplate | NativeTemplateProduct | null {
	if (typeof nativeProductOrTemplate === 'string') {
		const product = _getProductFromName(nativeProductOrTemplate);

		// TODO remove template direct invocation support eventually
		if (product === null) {
			const template = _getTemplateFromName(nativeProductOrTemplate);

			if (template !== null) {
				logger.warn('Native template rendered with template name. Direct invocation of templates is deprecated, please use products instead.');
			}

			return template;
		}

		return product;
	}

	return nativeProductOrTemplate;
}

function _isProduct(spec: NativeTemplate | NativeTemplateProduct | null): spec is NativeTemplateProduct {
	return isObject(spec) && 'templates' in spec;
}

function _getTemplateFromName(templateName: string | null): NativeTemplate | null {
	return window.advert.nativeTemplates.templates?.find(({name}) => name === templateName) ?? null;
}

function _getProductFromName(templateName: string): NativeTemplateProduct | null {
	return window.advert.nativeTemplates.products?.[_getProductName(templateName)] ?? null;
}

function _getProductName(name: string): string {
	return name.endsWith(ENRICHMENTS_INDICATOR)
		? name.slice(0, -ENRICHMENTS_INDICATOR.length)
		: name;
}
