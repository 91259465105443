import {normalizeSpecialCharacters} from './string-util';
import {getFromCookie} from './storage';

export enum Comparer {
	Equals = 'eq',
	Contains = 'in',
	LessThan = 'lt',
	LessThanOrEqual = 'lte',
	GreaterThan = 'gt',
	GreaterThanOrEqual = 'gte',
	RegularExpression = 'regEx',
	StartsWith = 'sw',
	EndsWith = 'ew',
	Before = 'bf',
	After = 'af',
	Missing = 'missing'
}

export type Condition = {
	comparer?: Comparer | `!${Comparer}`
	field: string | Array<string>
	value: unknown
	normalized?: boolean
};

const FIELD_TYPES = {
	DATE: 'date',
	COOKIE: 'cookie:',
	EXPERIMENT: 'experiment:',
};

export function isConditionActive(condition: Condition, properties: AnyObject): boolean {
	const {
		comparer = Comparer.Equals,
		field,
		value,
		normalized = false
	} = condition;

	if (comparer.indexOf('!') === 0) {
		return !isConditionActive({
			...condition,
			'comparer': comparer.slice(1) as Comparer
		}, properties);
	}

	const fieldValue = _getFieldValue(field, properties, normalized);

	if (typeof fieldValue === 'undefined' || fieldValue === null) {
		return comparer === Comparer.Missing;
	}

	if (comparer === Comparer.Missing) {
		return false;
	}

	if (comparer === Comparer.Before || comparer === Comparer.After) {
		return _dateCompare(comparer, fieldValue, value);
	}

	if (comparer === Comparer.Equals) {
		return _equals(fieldValue, value);
	}

	if (comparer === Comparer.Contains) {
		return _contains(fieldValue, value);
	}

	if (comparer === Comparer.LessThan || comparer === Comparer.LessThanOrEqual
		|| comparer === Comparer.GreaterThan || comparer === Comparer.GreaterThanOrEqual) {
		return _quantitativeCompare(comparer, fieldValue, value as number);
	}

	if (comparer === Comparer.RegularExpression) {
		return _regexCompare(fieldValue, value);
	}

	if (comparer === Comparer.EndsWith) {
		return _endsWithCompare(fieldValue, value);
	}

	if (comparer === Comparer.StartsWith) {
		return _startsWithCompare(fieldValue, value);
	}

	throw new Error(`Unknown comparer '${comparer}' for conditional configuration`);
}

function _getFieldValue(field: string | Array<string>, properties: AnyObject, normalized: boolean) {
	if (field === FIELD_TYPES.DATE) {
		return Date.now();
	}

	if (typeof field === 'string' && field.startsWith(FIELD_TYPES.COOKIE)) {
		return getFromCookie(field.slice(FIELD_TYPES.COOKIE.length));
	}

	if (typeof field === 'string' && field.startsWith(FIELD_TYPES.EXPERIMENT)) {
		return _getExperimentGroup(field);
	}

	const fieldValue = Array.isArray(field)
		? field.reduce((obj, f) => obj?.[f], properties)
		: properties[field];

	if (normalized) {
		if (typeof fieldValue === 'string') {
			return normalizeSpecialCharacters(fieldValue);
		}

		if (Array.isArray(fieldValue)) {
			return fieldValue.map(normalizeSpecialCharacters);
		}
	}

	return fieldValue;
}

function _getExperimentGroup(field: string) {
	const experimentName = field.slice(FIELD_TYPES.EXPERIMENT.length),
		experiments = (window as Window).advert?.getActiveExperiments?.();

	return (experiments ?? []).find((experiment) => experiment.name === experimentName)?.group ?? null;
}

function _equals(fieldValue: unknown, value: unknown) {
	return JSON.stringify(fieldValue) === JSON.stringify(value);
}

function _contains(fieldValue: unknown, value: unknown | Array<unknown>): boolean {
	if (value && Array.isArray(value)) {
		return value.every((val) => _contains(fieldValue, val));
	}

	return (fieldValue as string).indexOf(value as string) !== -1;
}

function _quantitativeCompare(
	comparer: Comparer.LessThan | Comparer.LessThanOrEqual | Comparer.GreaterThan | Comparer.GreaterThanOrEqual,
	fieldValue: number | unknown,
	value: number
) {
	const fieldValueAsNumber = typeof fieldValue === 'number'
		? fieldValue
		: parseFloat(fieldValue as string);


	if (isNaN(fieldValueAsNumber)) {
		return false;
	}

	switch (comparer) {
		case Comparer.LessThan:
			return fieldValueAsNumber < value;

		case Comparer.LessThanOrEqual:
			return fieldValueAsNumber <= value;

		case Comparer.GreaterThan:
			return fieldValueAsNumber > value;

		case Comparer.GreaterThanOrEqual:
			return fieldValueAsNumber >= value;
	}
}

function _regexCompare(fieldValue: unknown, value: unknown): boolean {
	if (fieldValue && Array.isArray(fieldValue)) {
		return fieldValue.every((val) => _regexCompare(val, value));
	}

	const regex = new RegExp(value as string);

	return regex.test(fieldValue as string);
}

function _startsWithCompare(fieldValue: unknown, value: unknown) {
	if (fieldValue && Array.isArray(fieldValue)) {
		if (fieldValue.length > 0) {
			return fieldValue[0] === value;
		}

		return false;
	}

	return (fieldValue as string).startsWith(value as string);
}

function _endsWithCompare(fieldValue: unknown, value: unknown) {
	if (fieldValue && Array.isArray(fieldValue)) {
		if (fieldValue.length > 0) {
			return fieldValue[fieldValue.length - 1] === value;
		}

		return false;
	}

	return (fieldValue as string).endsWith(value as string);
}

function _dateCompare(comparer: Comparer.Before | Comparer.After, fieldValue: unknown, value: unknown): boolean {
	const
		// @ts-ignore
		fieldDate: Date = new Date(fieldValue),
		// @ts-ignore
		valueDate = new Date(value);

	if (isNaN(fieldDate.valueOf()) || isNaN(valueDate.valueOf())) {
		return false;
	}

	switch (comparer) {
		case Comparer.Before:
			return fieldDate < valueDate;

		case Comparer.After:
			return fieldDate > valueDate;
	}
}
