function waitForTCFAPI(): Promise<void> {
	return new Promise((resolve) => {
		function _iterator(iteration: number) {
			if (typeof (window as Privacy.Window).__tcfapi === 'function') {
				resolve();

				return;
			}

			setTimeout(() => _iterator(++iteration), iteration * 100);
		}

		_iterator(0);
	});
}

async function getTCData(): Promise<Privacy.tcData> {
	await waitForTCFAPI();

	return new Promise((resolve, reject) => {
		(window as Privacy.Window).__tcfapi!('getTCData', 2, (tcData: Privacy.tcData, tcSuccess: boolean) => {
			if (tcSuccess) {
				resolve(tcData);
			} else {
				reject();
			}
		});
	});
}

export async function hasConsent(purposes: Array<string> = [], vendors: Array<string> = []): Promise<boolean> {
	try {
		const tcData = await getTCData();

		return purposes.every((v) => tcData?.purpose.consents[v])
			&& vendors.every((v) => tcData?.vendor.consents[v]);
	} catch {
		return false;
	}
}
