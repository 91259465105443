import Label, {LabelConfig} from './Label';
import {ValueType} from '../model/NativeAdObject';
import BaseButton, {BaseButtonConfig} from './subcomponents/BaseButton';
import {trackClickout} from '../../services/trackingService';
import {Component} from './index';

export type ButtonConfig = {
	type: 'button'
	label: LabelConfig
	valueType?: ValueType
} & BaseButtonConfig;

export default class Button extends BaseButton {
	label: Label;
	valueType?: ValueType;

	constructor(config: ButtonConfig) {
		super(config);

		this.label = new Label(config.label, true);
		this.valueType = config.valueType;
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = super.render(renderContext);

		if (this.valueType && renderContext.adObject.getValue(this.valueType)) {
			el.addEventListener('click', () => {
				window.open(renderContext.adObject.getValue(this.valueType!), '_blank');
				trackClickout(renderContext);
			});
		}

		el.appendChild(this.label.render(renderContext));

		return el;
	}

	getChildren(): Array<Component> {
		return [this.label];
	}
}
