import Label, {LabelConfig} from './Label';
import {DEFAULT_COLORS, getColorString, getSpacingString} from '../util/style-util';
import BaseComponent, {BaseComponentConfig} from './BaseComponent';
import {Component} from './index';


export type HeaderConfig = {
	type: 'header'
	label: LabelConfig
	padding?: Spacing
	lineColor?: Color
} & BaseComponentConfig;

export default class Header extends BaseComponent {
	label: Label;
	padding?: Spacing;
	lineColor?: Color;

	constructor(config: HeaderConfig) {
		super(config);

		this.label = new Label(config.label, true);
		this.padding = config.padding;
		this.lineColor = config.lineColor;
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('div');

		renderContext.style.style(el, {
			'display': 'flex',
			'alignItems': 'center',
			'width': '100%',
			'boxSizing': 'border-box',
			'padding': getSpacingString(this.padding)
		});

		el.appendChild(this.#createLine(renderContext));
		el.appendChild(this.label.render(renderContext));
		el.appendChild(this.#createLine(renderContext));

		return el;
	}

	#createLine(renderContext: RenderContext) {
		const el = document.createElement('div');

		renderContext.style.style(el, {
			'height': '1px',
			'flexGrow': '1',
			'backgroundColor': getColorString(renderContext, this.lineColor) ?? DEFAULT_COLORS.TRANSPARENT
		});

		return el;
	}

	getChildren(): Array<Component> {
		return [this.label];
	}
}
