import {DEFAULT_COLORS, getBorderString, getColorString, getSpacingString} from '../util/style-util';
import {buildComponent, Component, ComponentConfig} from './index';
import BaseComponent, {BaseComponentConfig} from './BaseComponent';

export type BoxConfig = {
	type: 'box'
	padding?: Spacing
	backgroundColor?: Color
	width?: number
	height?: number
	cornerRadius?: number
	borderColor?: Color
	borderWidth?: number
	views: Array<ComponentConfig>
} & BaseComponentConfig;

export default class Box extends BaseComponent {
	padding?: Spacing;
	backgroundColor?: Color;
	width?: number;
	height?: number;
	cornerRadius?: number;
	borderColor?: Color;
	borderWidth?: number;
	views: Array<Component>;

	constructor(config: BoxConfig, renderConfig: RenderConfig) {
		super(config);

		this.padding = config.padding;
		this.backgroundColor = config.backgroundColor;
		this.width = config.width;
		this.height = config.height;
		this.cornerRadius = config.cornerRadius;
		this.borderColor = config.borderColor;
		this.borderWidth = config.borderWidth;
		this.views = config.views.map((c) => buildComponent(c, renderConfig));
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('div');

		renderContext.style.style(el, {
			'position': 'relative',
			'width': '100%',
			'flexGrow': '1',
			'flexShrink': '1',
			'overflow': 'hidden',
			'boxSizing': 'border-box',
			'pointerEvents': 'none', // Allow click events to go through layers
			'backgroundColor': getColorString(renderContext, this.backgroundColor) ?? DEFAULT_COLORS.TRANSPARENT,
			'padding': getSpacingString(this.padding),

			'> *:not(:first-child)': {
				'position': 'absolute',
				'top': 0,
				'left': 0
			}
		});

		if (typeof this.width === 'number') {
			renderContext.style.style(el, {
				'width': `${this.width}px`
			});
		}

		if (typeof this.height === 'number') {
			renderContext.style.style(el, {
				'height': `${this.height}px`
			});
		}

		if (typeof this.borderColor !== 'undefined' || typeof this.borderWidth !== 'undefined') {
			renderContext.style.style(el, {
				'border': getBorderString(renderContext, {
					'width': this.borderWidth,
					'color': this.borderColor
				})
			});
		}

		if (typeof this.cornerRadius === 'number') {
			renderContext.style.style(el, {
				'borderRadius': `${this.cornerRadius}px`,
			});
		}

		this.views.forEach((component: Component) => {
			el.appendChild(component.render(renderContext));
		});

		return el;
	}

	getChildren(): Array<Component> {
		return this.views;
	}
}
