import {DEFAULT_COLORS, getBorderString, getColorString, getSpacingString} from '../util/style-util';
import {ValueType} from '../model/NativeAdObject';
import BaseComponent, {BaseComponentConfig} from './BaseComponent';
import logger from '../../util/logger';

export type ImageConfig = {
	type: 'image'
	padding?: Spacing
	contentMode?: 'fit' | 'fill'
	backgroundColor?: Color
	size?: Size
	cornerRadius?: number
	borderColor?: Color
	borderWidth?: number
	assetRefWeb?: string
	valueType?: ValueType
} & BaseComponentConfig;

export default class Image extends BaseComponent {
	margin?: Spacing;
	contentMode?: 'fit' | 'fill';
	backgroundColor?: Color;
	width?: number;
	height?: number;
	cornerRadius?: number;
	borderColor?: Color;
	borderWidth?: number;
	assetRef?: string;
	valueType: ValueType;

	constructor(config: ImageConfig) {
		super(config);

		this.margin = config.padding; // Padding to margin because it should go OUTSIDE the background color and image
		this.contentMode = config.contentMode;
		this.backgroundColor = config.backgroundColor;
		this.width = config.size?.width;
		this.height = config.size?.height;
		this.cornerRadius = config.cornerRadius;
		this.borderColor = config.borderColor;
		this.borderWidth = config.borderWidth;
		this.assetRef = config.assetRefWeb;
		this.valueType = config.valueType ?? ValueType.none;
	}

	render(renderContext: RenderContext): HTMLElement {
		const container = document.createElement('div');

		renderContext.style.style(container, {
			'flexGrow': '0',
			'flexShrink': '0',
			'overflow': 'hidden',
			'boxSizing': 'border-box',
			'margin': getSpacingString(this.margin),
			'backgroundColor': getColorString(renderContext, this.backgroundColor) ?? DEFAULT_COLORS.TRANSPARENT,
			'width': typeof this.width === 'number' ? `${this.width}px`
				// Remove margin from width because border-box does not account for margin
				: `calc(100% - ${(this.margin?.left ?? 0) + (this.margin?.right ?? 0)}px)`,
			'height': typeof this.height === 'number' ? `${this.height}px`
				// Remove margin from height because border-box does not account for margin
				: `calc(100% - ${(this.margin?.top ?? 0) + (this.margin?.bottom ?? 0)}px)`,
		});

		if (typeof this.borderColor !== 'undefined' || typeof this.borderWidth !== 'undefined') {
			renderContext.style.style(container, {
				'border': getBorderString(renderContext, {
					'width': this.borderWidth,
					'color': this.borderColor
				})
			});
		}

		if (typeof this.cornerRadius === 'number') {
			renderContext.style.style(container, {
				'borderRadius': `${this.cornerRadius}px`,
			});
		}

		const imgTag = this.#createImage(renderContext);

		imgTag.addEventListener('error', (e) => {
			logger.warn('Could not load native template image', e);

			// Copllapse entire container
			container.style.display = 'none';
		});

		container.appendChild(imgTag);

		return container;
	}

	#createImage(renderContext: RenderContext) {
		const img = document.createElement('img');

		renderContext.style.style(img, {
			'width': '100%',
			'display': 'block',
		});

		switch (this.contentMode) {
			case 'fill': {
				renderContext.style.style(img, {
					// not just height, because Safari has issues with object-fit + height
					'min-height': '100%',
					'max-height': '100%',
					'objectFit': 'cover',
				});

				break;
			}

			case 'fit': {
				renderContext.style.style(img, {
					// not just height, because Safari has issues with object-fit + height
					'min-height': '100%',
					'max-height': '100%',
					'objectFit': 'contain',
				});

				break;
			}
		}

		img.setAttribute('src', this.assetRef ?? renderContext.adObject.getValue(this.valueType));

		return img;
	}
}
