import Label, {LabelConfig} from '../Label';
import BaseButton, {BaseButtonConfig} from '../subcomponents/BaseButton';
import {findInTree} from '../../util/component-util';
import Leadform from './Leadform';
import {trackLeadformImpression} from '../../../services/trackingService';
import {Component} from '../index';

export type LeadformGoToPageConfig = {
	type: 'leadformGoToPage'
	label: LabelConfig
	pageNumber: number
} & BaseButtonConfig;

export default class LeadformGoToPage extends BaseButton {
	label: Label;
	pageNumber: number;

	constructor(config: LeadformGoToPageConfig) {
		super(config);

		this.label = new Label(config.label, true);
		this.pageNumber = config.pageNumber;
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = super.render(renderContext);

		el.appendChild(this.label.render(renderContext));
		el.addEventListener('click', () => {
			const leadformComponent = findInTree<Leadform>(renderContext.componentTree, (c) => c.type === 'leadform');
			if (leadformComponent) {
				leadformComponent.goToPage(this.pageNumber);
				trackLeadformImpression(renderContext, leadformComponent, this.label);
			}
		});

		return el;
	}

	getChildren(): Array<Component> {
		return [this.label];
	}
}
