type FetchRequest = Modify<RequestInit, {
	timeout?: number;
}>;

export async function fetchBetter(url: string, request: FetchRequest = {}): Promise<Response> {
	const
		{timeout = 5000, ...options} = request,
		abortContr = new AbortController(),
		timeoutRef = setTimeout(() => {
			abortContr.abort();
		}, timeout);

	try {
		return await fetch(url, {
			...options,
			signal: abortContr.signal,
		});
	} finally {
		clearTimeout(timeoutRef);
	}
}
