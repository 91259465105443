import {Component} from '../components';
import Video, {VideoEvent, VideoState} from '../components/Video';

export function findInTree<T extends Component>(componentTree: Component, matcher: (component: Component) => boolean): T | null {
	if (matcher(componentTree)) {
		return componentTree as T;
	}

	for (const component of componentTree.getChildren()) {
		const result = findInTree(component, matcher);

		if (result != null)
			return result as T;
	}

	return null;
}

export function findAllTypesInTree<T extends Component>(componentTree: Component, types: string[]): T[] {
	return findAllInTree<T>(
		componentTree,
		(component) => types.includes(component.type)
	);
}

export function findAllInTree<T extends Component>(componentTree: Component, matcher: (component: Component) => boolean): T[] {
	const resultArray: T[] = [];

	if (matcher(componentTree)) {
		resultArray.push(componentTree as T);
	}

	for (const component of componentTree.getChildren()) {
		resultArray.push(...findAllInTree(component, matcher) as T[]);
	}

	return resultArray;
}

export type ShowOnStates = (VideoState | 'interacted')[];

export function showOnVideoStates(element: HTMLElement, video: Video, states: ShowOnStates) {
	function setDisplay() {
		const visible = states.includes(video.state) || video.interacted && states.includes('interacted');

		element.style.display = visible ? '' : 'none';
	}

	setDisplay();
	video.events.addEventListener(VideoEvent.StateChanged, setDisplay);
	video.events.addEventListener(VideoEvent.InteractionChanged, setDisplay);
}
