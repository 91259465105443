import {fetchBetter} from '../util/fetch';
import {getObjectProperty} from '../util/object-util';
import logger from '../util/logger';

export async function getFeedNativeAdObject(enrichment: FeedAdObjectEnrichments): Promise<Record<string, string>> {
	const response = await fetchBetter(buildRequestUrl(enrichment));

	if (response.status >= 300) {
		logger.error('Invalid response from feed filter service');

		throw new Error('Failed to fetch feed data');
	}

	const feedResult = (await response.json()) as FeedResult;

	return mapFeedResultToFields(feedResult, enrichment.fieldMapping);
}

function buildRequestUrl(enrichment: FeedAdObjectEnrichments): string {
	const queryParams = new URLSearchParams(window.advert?.nativeTemplates?.feedContext ?? {});

	return `${enrichment.url}?${queryParams.toString()}`;
}

function mapFeedResultToFields(feedResult: FeedResult, mapping: FeedAdObjectEnrichments['fieldMapping']): Record<string, string> {
	return Object.fromEntries(
		Object.entries(mapping)
			.map(([nativeField, mappingField]: [string, string]) => [
				nativeField,
				getObjectProperty(feedResult, mappingField.split('.'))
			])
			.filter(([, value]) => typeof value !== 'undefined')
	);
}
