import {buildComponent, ComponentConfig} from './components';
import NativeAdObject, {XandrNativeAdObjectConfig} from './model/NativeAdObject';
import StyleBase from './util/style-base';
import {attachClickHandlers} from '../services/clickHandlers';
import logger from '../util/logger';
import {measureAttention} from '../services/trackingService';

export default function render(adObjectCfg: XandrNativeAdObjectConfig, nativeTemplate: NativeTemplate, renderConfig: RenderConfig, productName?: string): HTMLElement {
	if (!adObjectCfg) {
		logger.error('Could not render template. Missing an ad object.');

		throw new Error('Native Renderer: Missing an ad object');
	}

	if (!nativeTemplate) {
		logger.error('Could not render template. Missing a native template.');

		throw new Error('Native Renderer: Missing a native template');
	}

	const wrapper = _createWrapper(nativeTemplate.name);

	_renderTemplate(wrapper, adObjectCfg, nativeTemplate, renderConfig, productName)
		.then((renderContext) => {
			measureAttention(renderContext)
				.catch((e) => logger.error('Something went wrong trying to measure attention.', e));
		})
		.catch((e) => logger.error('Something went wrong trying to render the template.', e));

	return wrapper;
}

async function _renderTemplate(wrapper: HTMLElement, adObjectConfig: XandrNativeAdObjectConfig, nativeTemplate: NativeTemplate, renderConfig: RenderConfig, productName?: string): Promise<RenderContext> {
	const shadowRoot = wrapper.attachShadow({'mode': 'open'}),
		adObject = await NativeAdObject.build(adObjectConfig, renderConfig),
		template = parseTemplate(nativeTemplate, renderConfig),
		rootComponent = buildComponent(template, renderConfig),
		renderContext: RenderContext = {
			adObject,
			shadowRoot,
			renderConfig,
			templateName: nativeTemplate.name,
			productName,
			componentTree: rootComponent,
			style: new StyleBase(shadowRoot)
		};

	shadowRoot.appendChild(attachClickHandlers(rootComponent.render(renderContext), renderContext));

	return renderContext;
}

export function parseTemplate(nativeTemplate: NativeTemplate, renderConfig: RenderConfig): ComponentConfig {
	if (typeof nativeTemplate.template == 'object') {
		return nativeTemplate.template;
	}

	try {
		const appliedTemplate = _applyVariables(nativeTemplate.template, nativeTemplate.variables ?? renderConfig.variables ?? {});

		return JSON.parse(appliedTemplate);
	} catch (e) {
		logger.error('Could not parse template. Maybe some variables weren\'t provided?', nativeTemplate, renderConfig, e);

		throw e;
	}
}

function _applyVariables(str: string, variables: Record<string, unknown>): string {
	return Object.entries(variables).reduce((agg, [variableName, value]) => {
		return agg.split(`%${variableName}%`).join(`${value}`);
	}, str);
}

function _createWrapper(templateName: string): HTMLElement {
	const wrapper = document.createElement('div');

	// Not via CSS/style() as it's outside the Shadow root
	wrapper.style.textAlign = 'left';
	wrapper.style.fontStyle = 'normal';
	wrapper.style.fontVariant = 'normal';
	wrapper.style.textTransform = 'none';
	wrapper.style.lineHeight = 'normal';

	wrapper.setAttribute('data-native-template', templateName);
	wrapper.setAttribute('id', `native-render-${Math.random().toString().slice(2)}`);

	return wrapper;
}
